import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"menu-button",attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c(VList,[(typeof _vm.reserveItem.ruleId !== 'undefined')?_c(VListItem,{on:{"click":_vm.goToRecorded}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-filmstrip-box-multiple")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("recorded")])],1)],1):_vm._e(),(!!_vm.disableEdit === false)?_c(VListItem,{on:{"click":_vm.goToEdit}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("edit")])],1)],1):_vm._e(),(_vm.reserveItem.isConflict !== true)?_c(VListItem,{on:{"click":_vm.onClickDelete}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v(_vm._s(_vm.getDeleteButtonIcon()))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.getDeleteMenuText()))])],1)],1):_vm._e()],1)],1),(_vm.isOpened === true)?_c('div',{staticClass:"menu-background",on:{"click":_vm.onClickMenuBackground}}):_vm._e(),_c('ReserveDeleteDialog',{attrs:{"isOpen":_vm.isOpenDeleteDialog,"reserveItem":_vm.reserveItem},on:{"update:isOpen":function($event){_vm.isOpenDeleteDialog=$event},"update:is-open":function($event){_vm.isOpenDeleteDialog=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }