import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ 'needs-decoration': !!_vm.needsDecoration }},[_vm._l((_vm.reserves),function(reserve){return _c(VCard,{key:reserve.id,staticClass:"reserve-card mx-auto",class:_vm.getClass(reserve),staticStyle:{"cursor":"pointer"},attrs:{"flat":!!_vm.flat}},[_c(VListItem,{staticClass:"px-3",attrs:{"three-line":""}},[_c('div',{staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.clickItem(reserve)}}},[_c(VListItemContent,[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"subtitle-1 font-weight-black"},[(reserve.display.isRule === true)?_c(VIcon,{staticClass:"reserve-icon"},[_vm._v("mdi-calendar")]):_c(VIcon,{staticClass:"reserve-icon"},[_vm._v("mdi-timer-outline")]),_c('span',{staticClass:"pt-1 pl-1"},[_vm._v(_vm._s(reserve.display.name))])],1),_c(VSpacer),(_vm.isEditMode === false)?_c('ReserveMenu',{attrs:{"reserveItem":reserve.reserveItem,"disableEdit":_vm.disableEdit}}):_vm._e()],1),_c('div',{staticClass:"subtitle-2 font-weight-light"},[_vm._v(_vm._s(reserve.display.channelName))]),_c('div',{staticClass:"caption font-weight-light mb-2"},[_vm._v(" "+_vm._s(reserve.display.day)+"("+_vm._s(reserve.display.dow)+") "+_vm._s(reserve.display.startTime)+" ~ "+_vm._s(reserve.display.endTime)+" ("+_vm._s(reserve.display.duration)+"分) ")]),_c('div',{staticClass:"body-2 font-weight-light"},[_vm._v(_vm._s(reserve.display.description))])])],1)])],1)}),_c('ReserveDialog',{attrs:{"isOpen":_vm.isOpenDialog,"reserve":_vm.dialogReserve},on:{"update:isOpen":function($event){_vm.isOpenDialog=$event},"update:is-open":function($event){_vm.isOpenDialog=$event}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }