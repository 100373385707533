import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMain,[_c('TitleBar',{attrs:{"title":"放映中"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [(_vm.isTabView === true && _vm.onAirState.getSchedules().length > 0)?_c(VTabs,{attrs:{"centered":""},model:{value:(_vm.onAirState.selectedTab),callback:function ($$v) {_vm.$set(_vm.onAirState, "selectedTab", $$v)},expression:"onAirState.selectedTab"}},_vm._l((_vm.onAirState.getTabs()),function(item){return _c(VTab,{key:item,attrs:{"href":`#${item}`}},[_vm._v(_vm._s(item))])}),1):_vm._e()]},proxy:true}])}),_c('transition',{attrs:{"name":"page"}},[(_vm.onAirState.getSchedules().length > 0)?_c('div',[(_vm.isTabView === true)?_c(VTabsItems,{model:{value:(_vm.onAirState.selectedTab),callback:function ($$v) {_vm.$set(_vm.onAirState, "selectedTab", $$v)},expression:"onAirState.selectedTab"}},_vm._l((_vm.onAirState.getTabs()),function(item){return _c(VTabItem,{key:item,attrs:{"value":`${item}`}},[_c('OnAirCard',{attrs:{"items":_vm.onAirState.getSchedules(item),"reserveIndex":_vm.onAirState.getReserveIndex()}})],1)}),1):_c('div',[_c('OnAirCard',{attrs:{"items":_vm.onAirState.getSchedules(),"reserveIndex":_vm.onAirState.getReserveIndex()}})],1)],1):_vm._e()]),_c('div',{staticStyle:{"visibility":"hidden"}},[_vm._v("dummy")]),_c('OnAirSelectStream'),_c('ProgramDialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }