import { render, staticRenderFns } from "./OnAir.vue?vue&type=template&id=25335d19&scoped=true"
import script from "./OnAir.vue?vue&type=script&lang=ts"
export * from "./OnAir.vue?vue&type=script&lang=ts"
import style0 from "./OnAir.vue?vue&type=style&index=0&id=25335d19&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25335d19",
  null
  
)

export default component.exports