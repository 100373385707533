import { render, staticRenderFns } from "./RuleTableItems.vue?vue&type=template&id=bca7555a&scoped=true"
import script from "./RuleTableItems.vue?vue&type=script&lang=ts"
export * from "./RuleTableItems.vue?vue&type=script&lang=ts"
import style0 from "./RuleTableItems.vue?vue&type=style&index=0&id=bca7555a&prod&lang=sass&scoped=true"
import style1 from "./RuleTableItems.vue?vue&type=style&index=1&id=bca7555a&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca7555a",
  null
  
)

export default component.exports