import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.crrUser == null)?_c('div',[_c(VContainer,[_c(VCard,[_c(VForm,{ref:"form"},[_c(VCol,[_c(VCardTitle,{staticClass:"text-h9"},[_vm._v("[login] - stktv.incho.tech")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"email","type":"email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"label":"password","type":"password","required":""},model:{value:(_vm.pswd),callback:function ($$v) {_vm.pswd=$$v},expression:"pswd"}})],1)],1),_c(VCol,[_c(VBtn,{staticClass:"mb-3",attrs:{"block":"","outlined":""},on:{"click":_vm.login}},[_vm._v("login")])],1)],1)],1)],1)],1):_c('div',[_c('AppContent')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }