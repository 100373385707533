import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"recorded-detail-select-stream"},[(_vm.isRemove === false)?_c(VDialog,{attrs:{"max-width":"400","scrollable":""},model:{value:(_vm.dialogState.isOpen),callback:function ($$v) {_vm.$set(_vm.dialogState, "isOpen", $$v)},expression:"dialogState.isOpen"}},[(_vm.dialogState.title !== null)?_c(VCard,[_c('div',{staticClass:"pa-4 pb-0"},[_c('div',[_vm._v(_vm._s(_vm.dialogState.title))]),_c('div',{staticClass:"d-flex"},[_c(VSelect,{staticStyle:{"max-width":"120px"},attrs:{"items":_vm.dialogState.streamTypeItems,"menu-props":{ auto: true }},on:{"change":_vm.updateModeItems},model:{value:(_vm.dialogState.selectedStreamType),callback:function ($$v) {_vm.$set(_vm.dialogState, "selectedStreamType", $$v)},expression:"dialogState.selectedStreamType"}}),(_vm.isHiddenStreamMode === false)?_c(VSelect,{attrs:{"items":_vm.dialogState.streamModeItems,"menu-props":{ auto: true }},model:{value:(_vm.dialogState.selectedStreamMode),callback:function ($$v) {_vm.$set(_vm.dialogState, "selectedStreamMode", $$v)},expression:"dialogState.selectedStreamMode"}}):_vm._e()],1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v("キャンセル")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.view}},[_vm._v("視聴")])],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }