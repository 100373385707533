import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isRemove === false)?_c(VDialog,{attrs:{"persistent":_vm.isClearing,"max-width":"300","scrollable":""},model:{value:(_vm.dialogModel),callback:function ($$v) {_vm.dialogModel=$$v},expression:"dialogModel"}},[(_vm.isClearing === false)?_c(VCard,[_c(VCardText,{staticClass:"pa-4"},[_c('div',{staticClass:"text--primary"},[_vm._v("データベースに登録されていないファイルおよびディレクトリを削除します。実行しますか?")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogModel = false}}},[_vm._v("キャンセル")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.execute}},[_vm._v("実行")])],1)],1):_c(VCard,[_c(VCardText,{staticClass:"pa-4"},[_c('h3',[_vm._v("クリーンアップ中")]),_c(VProgressLinear,{staticClass:"my-5",attrs:{"indeterminate":"","rounded":"","height":"6"}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }