import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VCard,{class:{ 'selected-color': _vm.item.isSelected === true },attrs:{"ripple":false}},[_c('div',{staticClass:"d-flex my-1 recorded-small-card",on:{"click":_vm.clickItem}},[_c(VImg,{staticClass:"thumbnail",attrs:{"aspect-ratio":"1.7778","src":_vm.item.display.topThumbnailPath,"eager":""},on:{"error":function($event){this.src = './img/noimg.png'}}}),_c('div',{staticClass:"content pa-2 my-auto"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text mt-1 subtitle-2 font-weight-bold"},[_vm._v(_vm._s(_vm.item.display.name))]),(_vm.isEditMode === false)?_c('div',{staticClass:"menu-wrap"},[_c(VBtn,{staticClass:"menu-button",attrs:{"icon":""},on:{"click":_vm.openCancelDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1):_vm._e()]),_c('div',{staticClass:"text caption font-weight-light"},[_vm._v(_vm._s(_vm.item.display.channelName))]),_c('div',{staticClass:"text caption font-weight-light"},[_vm._v(_vm._s(_vm.item.display.time)+" ("+_vm._s(_vm.item.display.duration)+" m)")]),_c('div',{staticClass:"text caption font-regular"},[_vm._v(_vm._s(_vm.item.display.mode))]),_c('div',{staticClass:"text caption font-regular"},[_vm._v(_vm._s(_vm.item.display.encodeInfo))]),(typeof _vm.item.display.percent !== 'undefined')?_c(VProgressLinear,{attrs:{"buffer-value":"100","value":_vm.item.display.percent}}):_vm._e()],1)],1)]),_c('EncodeCancelDialog',{attrs:{"isOpen":_vm.isOpenCancelDialog,"item":_vm.item.encodeItem},on:{"update:isOpen":function($event){_vm.isOpenCancelDialog=$event},"update:is-open":function($event){_vm.isOpenCancelDialog=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }