import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"guide-day-select-dialog"},[(_vm.isRemove === false)?_c(VDialog,{attrs:{"max-width":"150","scrollable":""},model:{value:(_vm.dialogModel),callback:function ($$v) {_vm.dialogModel=$$v},expression:"dialogModel"}},[_c(VCard,{staticClass:"py-2"},_vm._l((_vm.dayItems),function(item){return _c(VListItem,{key:item.value,attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.goto(item.value)}}},[_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"text-align":"center"},domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }