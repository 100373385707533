import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isRemove === false)?_c(VDialog,{attrs:{"max-width":"300","scrollable":""},model:{value:(_vm.dialogModel),callback:function ($$v) {_vm.dialogModel=$$v},expression:"dialogModel"}},[_c(VCard,[_c('div',{staticClass:"pa-4 pb-0"},[_c('div',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.recordedItem.name)+" を削除しますか?")]),_c('div',{staticClass:"checkboxs py-2"},_vm._l((_vm.videoFiles),function(v){return _c(VCheckbox,{key:v.id,staticClass:"my-0",attrs:{"label":v.name},model:{value:(v.isDelete),callback:function ($$v) {_vm.$set(v, "isDelete", $$v)},expression:"v.isDelete"}})}),1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogModel = false}}},[_vm._v("キャンセル")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteRecorded}},[_vm._v("削除")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }