import { render, staticRenderFns } from "./VideoContainer.vue?vue&type=template&id=a182e316&scoped=true"
import script from "./VideoContainer.vue?vue&type=script&lang=ts"
export * from "./VideoContainer.vue?vue&type=script&lang=ts"
import style0 from "./VideoContainer.vue?vue&type=style&index=0&id=a182e316&prod&lang=sass&scoped=true"
import style1 from "./VideoContainer.vue?vue&type=style&index=1&id=a182e316&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a182e316",
  null
  
)

export default component.exports