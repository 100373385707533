import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"800"}},[_c(VList,_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.display.id,class:{ 'selected-color': item.isSelected === true }},[_c(VListItemContent,[_c('div',{staticClass:"pl-2 d-flex flex-row flex-wrap align-center",on:{"click":function($event){return _vm.selectItem(item)}}},[(_vm.isEditMode === false)?_c(VSwitch,{staticClass:"toggle",attrs:{"hide-details":"","dense":"","value":""},on:{"change":function($event){return _vm.changeState(item)}},model:{value:(item.display.isEnable),callback:function ($$v) {_vm.$set(item.display, "isEnable", $$v)},expression:"item.display.isEnable"}}):_vm._e(),_c('div',{staticClass:"keyword"},[_c(VListItemTitle,{staticClass:"subtitle-1"},[_vm._v(_vm._s(item.display.keyword))])],1),_c('div',{staticClass:"menu d-flex align-center"},[_c('span',{staticClass:"mx-2 grey--text text--lighten-1"},[_vm._v(_vm._s(item.display.reservationsCnt))]),(_vm.isEditMode === false)?_c('RuleItemMenu',{attrs:{"ruleItem":item}}):_vm._e()],1)],1)])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }