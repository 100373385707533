import { render, staticRenderFns } from "./WatchOnAirInfoCard.vue?vue&type=template&id=7ef4b101"
import script from "./WatchOnAirInfoCard.vue?vue&type=script&lang=ts"
export * from "./WatchOnAirInfoCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports