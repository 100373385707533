import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VAppBar,{attrs:{"app":"","color":_vm.appBarColor,"clipped-left":_vm.navigationState.isClipped}},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.onClose}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.onSelectAll}},[_c(VIcon,[_vm._v("mdi-select-all")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.onDelete}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }