import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"reserves-main-menu"},[_c(VMenu,{staticClass:"menu",attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"menu-button",attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c(VList,[_c(VListItem,{on:{"click":_vm.updateReserves}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-update")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("予約情報更新")])],1)],1),_c(VListItem,{on:{"click":_vm.genreSetting}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-bookmark")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("表示ジャンル")])],1)],1),_c(VListItem,{on:{"click":_vm.gotoSetting}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-cog")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("表示設定")])],1)],1)],1)],1),_c('GuideGenreSettingDialog',{attrs:{"isOpen":_vm.isOpenGenreSettingDialog},on:{"update:isOpen":function($event){_vm.isOpenGenreSettingDialog=$event},"update:is-open":function($event){_vm.isOpenGenreSettingDialog=$event},"update":_vm.onUpdateGenreSetting}}),(_vm.isOpened === true)?_c('div',{staticClass:"menu-background",on:{"click":_vm.onClickMenuBackground}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }