import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"recorded-main-menu"},[_c(VMenu,{staticClass:"menu",attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"menu-button",attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c(VList,[_c(VListItem,{on:{"click":_vm.edit}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("編集")])],1)],1),_c(VListItem,{on:{"click":_vm.cleanup}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-delete")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("クリーンアップ")])],1)],1),_c(VListItem,{on:{"click":_vm.upload}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-upload")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("アップロード")])],1)],1)],1)],1),(_vm.isOpened === true)?_c('div',{staticClass:"menu-background",on:{"click":_vm.onClickMenuBackground}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }