import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"menu-button",attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":_vm.onRecorded}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-filmstrip-box-multiple")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("recorded")])],1)],1),_c(VListItem,{on:{"click":_vm.onEdit}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("edit")])],1)],1),_c(VListItem,{on:{"click":_vm.openDeleteDialog}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v("mdi-delete")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("delete")])],1)],1)],1)],1),_c('RuleDeleteDialog',{attrs:{"isOpen":_vm.isOpenDeleteDialog,"ruleItem":_vm.ruleItem},on:{"update:isOpen":function($event){_vm.isOpenDeleteDialog=$event},"update:is-open":function($event){_vm.isOpenDeleteDialog=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }