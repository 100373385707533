import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{attrs:{"clipped":_vm.navigationState.isClipped,"permanent":_vm.navigationState.type === 'permanent',"temporary":_vm.navigationState.type === 'temporary',"app":"","overflow":""},model:{value:(_vm.navigationState.openState),callback:function ($$v) {_vm.$set(_vm.navigationState, "openState", $$v)},expression:"navigationState.openState"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"title"},[_vm._v("stktv")])],1)],1),_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"multiple":"","max":0}},_vm._l((_vm.navigationState.items),function(item,index){return _c(VListItem,{key:item.id,class:_vm.getNavigationItemClass(index),attrs:{"link":"","disabled":item.herf === null},on:{"click":function($event){return _vm.route(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1),_c('div',{staticClass:"list-dummy"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }